















































































































































import { Component, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import GeneralApi from "@/networks/GeneralApi";
import store from "@/store";

@Component({})
export default class Quick extends Vue {
  private offices = [];

  mounted() {
    this.office();
  }

  public async office(): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().office({});
      this.offices = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }
}
